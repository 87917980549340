import React from "react"
import Share from "../components/social-share"

const Episode = (props) => (
    <div className="episode">

        <div className="video">
            <div className="video-container">
                <iframe title="Latest Episode. " src={`https://www.youtube.com/embed/` + props.embedId} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </div>
        </div>

        <div className="episode-info">
            <h2>Keep it Social: Episode {props.episodeNumber}</h2>
            <div>
                <p>{props.description}</p>
                <Share url={`https://www.youtube.com/watch?v=` + props.embedId} />
            </div>
        </div>
    </div>
)

export default Episode
