import React, { useEffect } from "react"
import { Link, useStaticQuery, graphql } from 'gatsby'
import { v4 as uuidv4 } from 'uuid'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Tagline from "../components/tagline"
import Promo from "../components/promo-text"
import ContactUs from "../components/contact-us"
import LatestEpisode from "../components/latest-episode"
import Episode from "../components/episode"

function HomePage() {
    let data = useStaticQuery(
        graphql `
        query {
            allEpisode(skip: 1, limit: 9) {
              nodes {
                embedId
                episodeNumber
                pubDate
                description
              }
            }
          }`
    )

    let episodes = data.allEpisode.nodes

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <Layout>
            <SEO title="Home" />
            <Tagline content="Where our timeline comes to life."/>
            <LatestEpisode />
            <ContactUs />
            <Promo color="light">
                {episodes.map((episode) => {
                    return (
                        <Episode
                            key={uuidv4()}
                            embedId={episode.embedId}
                            episodeNumber={episode.episodeNumber}
                            description={episode.description}
                        />
                    )
                })
                }
            </Promo >
            <div className="textbar">
                <Link to="/earlier-episodes/">Earlier episodes</Link>
            </div>
        </Layout>
    )
}
export default HomePage
