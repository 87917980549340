import React from "react"
import Share from "./social-share"
import { useStaticQuery, graphql } from 'gatsby'


function LatestEpisode() {
    const data = useStaticQuery(
        graphql `
        query {
            allEpisode(limit: 1) {
              nodes {
                embedId
                episodeNumber
                pubDate
                description
              }
            }
          }
              `
    )

    const episode = data.allEpisode.nodes[0]

    return (
        <>
            <div className="latest-episode">
                <div className="wrapper">
                    <div><span>LATEST EPISODE</span></div>
                    <div className="video-container">
                        <iframe title="Latest Episode. " src={`https://www.youtube.com/embed/` + episode.embedId} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    </div>
                </div>
            </div>

            <div className="promo-light">
                <div className="latest-info">
                    <div className="wrapper">
                        <h2>Keep it Social: Episode {episode.episodeNumber}</h2>
                        <p>{episode.description}</p>
                        <Share url={`https://www.youtube.com/watch?v=` + episode.embedId}/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default LatestEpisode
